const domready = () => {
  if (document.querySelectorAll('[data-bundle]').length) {
    import('./rehydrator')
      .then(({ default: rehydrator }) => {
        rehydrator();
      })
      .catch((e) => {
        if (window.TrackJS) {
          window.TrackJS.console.error('Fails to import rehydrator', e);
        } else {
          console.error(e); // eslint-disable-line
        }
      });
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', domready);
} else {
  domready();
}
